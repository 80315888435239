<template>
  <v-snackbar v-model="snackbar" :color="snackbarColor">
    <div class="snackbar_body">
      <div class="snackbar_item">
        <v-icon dark>
          {{ snackbarIcon }}
        </v-icon>
      </div>
      <div class="snackbar_item">
        {{ snackbarMessage }}
      </div>
      <div class="snackbar_item">
        <v-btn variant="text" @click="snackbar = false"> CLOSE </v-btn>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
const SNACKBAR_SUCCESS = "success";
const SNACKBAR_WARNING = "warning";
const SNACKBAR_ERROR = "error";

export default {
  data: () => ({
    snackbar: false,
    snackbarColor: "success",
    snackbarIcon: "",
    snackbarMessage: "",
    timeout: -1,
  }),

  methods: {
    /*
     * snackbarの初期化
     */
    init() {
      this.snackbar = false;
      this.snackbarMessage = "";
      this.setSnackbarStyle(SNACKBAR_SUCCESS);
    },

    /*
     * 完了メッセージの表示
     */
    dispSuccess(msg) {
      this.setSnackbarStyle(SNACKBAR_SUCCESS);
      this.snackbarMessage = msg;
      this.snackbar = true;
    },

    /*
     * 警告メッセージの表示
     */
    dispWarning(msg) {
      this.setSnackbarStyle(SNACKBAR_WARNING);
      this.snackbarMessage = msg;
      this.snackbar = true;
    },

    /*
     * エラーメッセージの表示
     */
    dispError(msg) {
      this.setSnackbarStyle(SNACKBAR_ERROR);
      this.snackbarMessage = msg;
      this.snackbar = true;
    },

    setSnackbarStyle(color) {
      switch (color) {
        case SNACKBAR_SUCCESS:
          this.snackbarColor = SNACKBAR_SUCCESS;
          this.snackbarIcon = "mdi-check-circle";
          break;
        case SNACKBAR_WARNING:
          this.snackbarColor = SNACKBAR_WARNING;
          this.snackbarIcon = "mdi-alert-circle";
          break;
        case SNACKBAR_ERROR:
          this.snackbarColor = SNACKBAR_ERROR;
          this.snackbarIcon = "mdi-alert";
          break;
        default:
          break;
      }
    },
  },
};
</script>

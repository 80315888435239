import axios from "axios";

function sendApi(url, options, method, data, funcOK, funcNG) {
  if (method == "GET") {
    axios
      .get(url, options)
      .then((response) => {
        funcOK(response);
      })
      .catch((e) => {
        funcNG(e);
        console.log("e:" + e);
      });
  } else if (method == "POST") {
    axios
      .post(url, data, options)
      .then((response) => {
        funcOK(response);
      })
      .catch((e) => {
        funcNG(e);
      });
  } else if (method == "PUT") {
    axios
      .put(url, data, options)
      .then((response) => {
        funcOK(response);
      })
      .catch((e) => {
        funcNG(e);
      });
  } else if (method == "DELETE") {
    axios
      .delete(url, options)
      .then((response) => {
        funcOK(response);
      })
      .catch((e) => {
        funcNG(e);
      });
  }
}

export default {
  sendApiGet(url, funcOK, funcNG) {
    const options = { withCredentials: true };
    sendApi(url, options, "GET", null, funcOK, funcNG);
  },

  sendApiPost(url, data, funcOK, funcNG) {
    const options = { withCredentials: true };
    sendApi(url, options, "POST", data, funcOK, funcNG);
  },

  sendApiPut(url, data, funcOK, funcNG) {
    const options = { withCredentials: true };
    sendApi(url, options, "PUT", data, funcOK, funcNG);
  },

  sendApiPutFalse(url, data, funcOK, funcNG) {
    const options = { withCredentials: false };
    sendApi(url, options, "PUT", data, funcOK, funcNG);
  },

  sendApiDelete(url, funcOK, funcNG) {
    const options = { withCredentials: true };
    sendApi(url, options, "DELETE", null, funcOK, funcNG);
  },

  sendApiMultipartPost(url, data, funcOK, funcNG) {
    const options = {
      headers: {
        "content-type": "multipart/form-data",
      },
      withCredentials: true,
    };
    sendApi(url, options, "POST", data, funcOK, funcNG);
  },

  sendApiMultipartPut(url, data, funcOK, funcNG) {
    const options = {
      headers: {
        "content-type": "multipart/form-data",
      },
      withCredentials: true,
    };
    sendApi(url, options, "PUT", data, funcOK, funcNG);
  },
  sendApi_1_1_Post(url, data, _accessToken = "", funcOK, funcNG) {
    const options = {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: false,
    };

    if (_accessToken) options.headers.authorization = "Bearer " + _accessToken;

    sendApi(url, options, "POST", data, funcOK, funcNG);
  },

  sendApi_1_1_Put(url, data, _accessToken = "", funcOK, funcNG) {
    const options = {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: false,
    };

    if (_accessToken) options.headers.authorization = "Bearer " + _accessToken;

    sendApi(url, options, "PUT", data, funcOK, funcNG);
  },

  sendApi_1_1_Delete(url, _accessToken = "", funcOK, funcNG) {
    const options = {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: false,
    };

    if (_accessToken) options.headers.authorization = "Bearer " + _accessToken;

    sendApi(url, options, "DELETE", null, funcOK, funcNG);
  },

  sendApi_1_1_Bearer_Get(url, _accessToken, funcOK, funcNG) {
    const options = {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        authorization: "Bearer " + _accessToken,
      },
      withCredentials: false,
    };
    sendApi(url, options, "GET", null, funcOK, funcNG);
  },

  sendApi_1_1_MultipartPost(url, data, _accessToken = "", funcOK, funcNG) {
    const options = {
      headers: {
        "content-type": "multipart/form-data",
      },
      withCredentials: false,
    };
    if (_accessToken) options.headers.authorization = "Bearer " + _accessToken;

    sendApi(url, options, "POST", data, funcOK, funcNG);
  },
  sendApi_1_1_MultipartPut(url, data, _accessToken = "", funcOK, funcNG) {
    const options = {
      headers: {
        "content-type": "multipart/form-data",
      },
      withCredentials: false,
    };
    if (_accessToken) options.headers.authorization = "Bearer " + _accessToken;
    sendApi(url, options, "PUT", data, funcOK, funcNG);
  },
  sendApi_1_1_MultipartDelete(url, Data, _accessToken = "", funcOK, funcNG) {
    const options = {
      headers: {
        "Content-type": "application/json",
      },
      withCredentials: false,
    };
    options.data = Data;
    console.log(options);
    if (_accessToken) options.headers.authorization = "Bearer " + _accessToken;
    sendApi(url, options, "DELETE", null, funcOK, funcNG);
  },
  sendApiFileGet(url, funcOK, funcNG) {
    const options = {
      withCredentials: true,
      responseType: "blob",
    };
    sendApi(url, options, "GET", null, funcOK, funcNG);
  },
  sendApi_1_1_FileGet(url, _accessToken, funcOK, funcNG) {
    const options = {
      headers: {},
      withCredentials: false,
      responseType: "blob",
    };
    if (_accessToken) options.headers.authorization = "Bearer " + _accessToken;
    sendApi(url, options, "GET", null, funcOK, funcNG);
  },
};

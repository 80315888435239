import { createRouter, createWebHashHistory } from "vue-router";
import VueCookies from "vue-cookies";

const routes = [
  {
    // ログイン画面
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      requiresAuth: false,
      drawerName: "",
    },
  },
  {
    // パスワード変更画面
    path: "/password",
    name: "PasswordChange",
    component: () => import("../views/Password_Change.vue"),
    meta: {
      requiresAuth: true,
      roles: ["salesagent", "physician", "company", "employee"],
      drawerName: "",
    },
  },
  {
    // パスワードリセット完了画面
    path: "/passwordResetSuccess",
    name: "PasswordResetSuccess",
    component: () => import("../views/Password_Reset_Success.vue"),
    meta: {
      requiresAuth: false,
      drawerName: "",
    },
  },
  {
    // パスワードリセット画面
    path: "/passwordReset/:reset_id",
    name: "PasswordReset",
    component: () => import("../views/Password_Change.vue"),
    meta: {
      requiresAuth: false,
      drawerName: "",
    },
  },
  {
    // アカウントロック解除画面
    path: "/temporary_Lock_Release",
    name: "TemporaryLockRelease",
    component: () => import("../views/Temporary_Lock_Release.vue"),
    meta: {
      requiresAuth: false,
      drawerName: "",
    },
  },
  {
    // TOP画面
    path: "/main",
    component: () => import("../views/dashboard/Index.vue"),
    meta: {
      requiresAuth: true,
      drawerName: "",
    },
    children: [
      {
        // トップ画面
        path: "",
        name: "Top",
        component: () => import("../views/dashboard/views/Top.vue"),
        meta: {
          roles: ["salesagent", "physician", "company", "employee"],
          drawerName: "Top",
        },
      },
      {
        // 売上詳細画面
        path: "/main/sales",
        name: "SalesDetails",
        component: () => import("../views/dashboard/views/Sales_Details.vue"),
        meta: {
          roles: [],
          drawerName: "SalesDetails",
        },
      },
      {
        // アカウント設定
        path: "/main/user/setting",
        name: "UserSetting",
        component: () => import("../views/dashboard/views/User_Setting.vue"),
        meta: {
          roles: ["salesagent", "physician", "company", "employee"],
          drawerName: "",
        },
      },
      {
        // パスワード変更
        path: "/main/password",
        name: "PasswordChange2",
        component: () =>
          import("../views/dashboard/views/Password_Change2.vue"),
        meta: {
          roles: ["salesagent", "physician", "company", "employee"],
          drawerName: "",
        },
      },
      {
        // OEM先企業
        path: "/main/salesagent",
        meta: {
          roles: [],
          drawerName: "Salesagent",
        },
        children: [
          {
            // OEM先企業一覧
            path: "/main/salesagent",
            name: "Salesagent",
            component: () =>
              import("../views/dashboard/views/Salesagent_List.vue"),
          },
          {
            // OEM先企業詳細
            path: "/main/salesagent/:salesagent_id",
            name: "SalesagentProfile",
            component: () =>
              import("../views/dashboard/views/Salesagent_Profile.vue"),
          },
          {
            // OEM先企業登録
            path: "/main/salesagent/regist",
            name: "SalesagentRegist",
            component: () =>
              import("../views/dashboard/views/Salesagent_Regist.vue"),
          },
          {
            // 契約者登録
            path: "/main/salesagent/:salesagent_id/company/regist",
            name: "CompanyRegist",
            component: () =>
              import("../views/dashboard/views/Company_Regist.vue"),
            meta: {
              roles: ["salesagent"],
            },
          },
          {
            // 契約情報登録
            path: "/main/salesagent/:salesagent_id/plan/regist",
            name: "PlanRegist",
            component: () => import("../views/dashboard/views/Plan_Regist.vue"),
          },
          {
            // 契約情報詳細
            path: "/main/salesagent/:salesagent_id/plan/:plan_id",
            name: "PlanProfile",
            component: () =>
              import("../views/dashboard/views/Plan_Profile.vue"),
            meta: {
              roles: ["salesagent"],
            },
          },
        ],
      },
      {
        // 契約者
        path: "/main/company",
        meta: {
          roles: [],
          drawerName: "Company",
        },
        children: [
          {
            // 契約者一覧
            path: "",
            name: "Company",
            component: () =>
              import("../views/dashboard/views/Company_List.vue"),
            meta: {
              roles: ["salesagent", "physician"],
            },
          },
          {
            // 契約者詳細
            path: "/main/company/:company_id",
            name: "CompanyProfile",
            component: () =>
              import("../views/dashboard/views/Company_Profile.vue"),
            meta: {
              roles: ["salesagent", "physician"],
            },
          },
          {
            // 契約者登録
            path: "/main/company/regist",
            name: "CompanyRegist2",
            component: () =>
              import("../views/dashboard/views/Company_Regist.vue"),
            meta: {
              roles: ["salesagent"],
            },
          },
          {
            // 部署詳細
            path: "/main/company/:company_id/department/:department_code",
            name: "DepartmentProfile",
            component: () =>
              import("../views/dashboard/views/Department_Profile.vue"),
            meta: {
              roles: ["salesagent", "physician", "company"],
            },
          },
          {
            // 部署登録
            path: "/main/company/:company_id/department/regist",
            name: "DepartmentRegist",
            component: () =>
              import("../views/dashboard/views/Department_Regist.vue"),
            meta: {
              roles: ["salesagent", "company"],
            },
          },
          {
            // 一般ユーザー登録
            path: "/main/company/:company_id/employee/regist",
            name: "EmployeeRegist",
            component: () =>
              import("../views/dashboard/views/Employee_Regist.vue"),
            meta: {
              roles: ["salesagent", "company"],
            },
          },
        ],
      },
      {
        // 一般ユーザー
        path: "/main/employee",
        meta: {
          roles: [],
          drawerName: "Employee",
        },
        children: [
          {
            // 一般ユーザー一覧
            path: "",
            name: "Employee",
            component: () =>
              import("../views/dashboard/views/Employee_List.vue"),
            meta: {
              roles: ["salesagent", "company", "physician"],
            },
          },
          {
            // 一般ユーザー登録
            path: "/main/employee/regist",
            name: "EmployeeRegist2",
            component: () =>
              import("../views/dashboard/views/Employee_Regist.vue"),
            meta: {
              roles: ["salesagent", "company"],
            },
          },
          {
            // 一般ユーザー詳細
            path: "/main/company/:company_id/employee/:employee_id/:detail_view_type",
            name: "EmployeeProfile",
            component: () =>
              import("../views/dashboard/views/Employee_Profile.vue"),
            meta: {
              roles: ["salesagent", "company", "physician"],
            },
          },
        ],
      },
      {
        // データ閲覧者
        path: "/main/physician",
        meta: {
          roles: [],
          drawerName: "Physician",
        },
        children: [
          {
            // データ閲覧者一覧
            path: "",
            name: "Physician",
            component: () =>
              import("../views/dashboard/views/Physician_List.vue"),
            meta: {
              roles: ["salesagent"],
            },
          },
          {
            // データ閲覧者詳細
            path: "/main/physician/:physician_id",
            name: "PhysicianProfile",
            component: () =>
              import("../views/dashboard/views/Physician_Profile.vue"),
            meta: {
              roles: ["salesagent"],
            },
          },
          {
            // データ閲覧者登録
            path: "/main/physician/regist",
            name: "PhysicianRegist",
            component: () =>
              import("../views/dashboard/views/Physician_Regist.vue"),
            meta: {
              roles: ["salesagent"],
            },
          },
          {
            // データ閲覧者の設定
            path: "/main/physician/setting",
            name: "PhysicianSetting",
            component: () =>
              import("../views/dashboard/views/Physician_Setting.vue"),
            meta: {
              roles: ["physician"],
            },
          },
        ],
      },
      {
        // 料金プラン
        path: "/main/pricemodel",
        meta: {
          roles: [],
          drawerName: "Pricemodel",
        },
        children: [
          {
            // 料金プラン一覧
            path: "",
            name: "Pricemodel",
            component: () =>
              import("../views/dashboard/views/Pricemodel_List.vue"),
          },
          {
            // 料金プラン詳細
            path: "/main/pricemodel/:pricemodel_id",
            name: "PricemodelProfile",
            component: () =>
              import("../views/dashboard/views/Pricemodel_Profile.vue"),
          },
          {
            // 料金プラン登録
            path: "/main/pricemodel/regist",
            name: "PricemodelRegist",
            component: () =>
              import("../views/dashboard/views/Pricemodel_Regist.vue"),
          },
        ],
      },
      {
        // 嚥下障害
        path: "/main/dysphagia",
        name: "Dysphagia",
        component: () => import("../views/dashboard/views/Dysphagia_List.vue"),
        meta: {
          roles: ["company"],
          engineType: "dysphagia",
          drawerName: "Dysphagia",
        },
      },
      {
        // MCI
        path: "/main/mci",
        name: "MsaMci",
        component: () => import("../views/dashboard/views/Mci_List.vue"),
        meta: {
          roles: ["company"],
          engineType: "mci",
          drawerName: "MsaMci",
        },
      },
    ],
  },
  {
    // 404
    path: "/404",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
    meta: {
      requiresAuth: false,
      drawerName: "",
    },
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // 遷移時にスクロールを一番上に
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
  history: createWebHashHistory(import.meta.env.BASE_URL),
});

router.beforeEach((to, from, next) => {
  if (to.name == undefined) {
    // ルーティング対象外へのリンクの場合、404ページへリダイレクト
    next({ name: "NotFound", replace: true });
  }

  // 認証必須なページ
  if (to.meta.requiresAuth) {
    let user = VueCookies.get("user");

    if (user === null) {
      // userが無い場合はログインページ
      sessionStorage.setItem("redirect_from", to.path);
      sessionStorage.setItem("redirect_hash", to.hash);
      next({ name: "Login" });
    } else {
      let to_next_page = false;
      if (user.user_type == "pst") {
        to_next_page = true;
      } else if (
        to.meta.roles.includes(user.user_type) &&
        (!("engineType" in to.meta) ||
          ("engineType" in to.meta &&
            to.meta.engineType in VueCookies.get("engineTypes")))
      ) {
        to_next_page = true;
      }

      if (to_next_page) {
        next();
      } else {
        // ページの表示に必要な権限が無い場合はNot Found
        next({ name: "NotFound", replace: true });
      }
    }
  } else {
    next();
  }
});

import { useDrawerIndexStore } from "@/stores/drawerSelectIndex.js";
router.afterEach((to) => {
  const drawerIndex = useDrawerIndexStore();
  drawerIndex.setIndex(to.meta?.drawerName);
});

export default router;

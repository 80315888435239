const _basePath = import.meta.env.VITE_APP_WEBDK_URL;

/*
const _basePathCommon = _basePath + 'common/';
*/
const _basePathCommon = _basePath;
const _basePathMimosys = _basePath + "mi1/";
// const _basePathSompo = _basePath + 'sompo/';
// const _basePathMciut = _basePath + 'mciut/';

const _postUrls = {
  // common
  // アクセストークン取得		/token
  login: _basePathCommon + "token",
  glogin: _basePathCommon + "oauth/google/token",
  logout: _basePathCommon + "user/logout",

  password_reset: _basePathCommon + "account/password/reset",
  password: _basePathCommon + "account/password",
  info_pst: _basePathCommon + "user/info/pst",

  // アカウント取得 PST権限 /pst/me
  info_user_pst: _basePathCommon + "pst/me",

  // アカウント取得 OEM先企業権限 /salesagent/me
  info_user_salesagent: _basePathCommon + "salesagent/me",

  // アカウント取得 契約者権限 /company/me
  info_user_company: _basePathCommon + "company/me",

  // アカウント取得 一般ユーザー権限 /employee/me
  info_user_employee: _basePathCommon + "employee/me",

  // アカウント取得 データ閲覧者権限 /physician/me
  info_user_physician: _basePathCommon + "physician/me",

  // OEM先企業情報一覧取得		/salesagent/list
  info_salesagent: _basePathCommon + "salesagent/list",

  // OEM先企業情報取得		/salesagent/{salesagent_id}
  detail_salesagent: _basePathCommon + "salesagent",

  // 契約者情報一覧取得		/company/list
  info_company: _basePathCommon + "company/list",

  // 契約者情報情報取得		/company/{employee_id}
  detail_company: _basePathCommon + "company",

  // 部署情報一覧取得		/company/{company_id}/department/list
  info_department: _basePathCommon + "company/{company_id}/department/list",

  // 部署情報取得		/company/{company_id}/department/{department_name}
  detail_department: _basePathCommon + "company/{company_id}/department",

  // 一般ユーザー情報一覧取得		/employee/list
  info_employee: _basePathCommon + "employee/list",

  // 一般ユーザー情報取得		/employee/{employee_id}
  detail_employee: _basePathCommon + "employee",

  // データ閲覧者情報一覧取得		/physician/list
  info_physician: _basePathCommon + "physician/list",

  // データ閲覧者情報取得		/physician/{physician_id}
  detail_physician: _basePathCommon + "physician",

  // 料金プラン一覧取得		/priceplan/list
  info_pricemodel: _basePathCommon + "priceplan/list",

  // 料金プラン取得		/priceplan/{priceplan_id}
  detail_pricemodel: _basePathCommon + "priceplan",

  // 契約情報一覧取得		/contract/list
  info_contract: _basePathCommon + "contract/list",

  // 契約情報取得		/contract/{contract_id}
  detail_contract: _basePathCommon + "contract",

  pricemodel_salesagent: _basePathCommon + "pricemodel/salesagent",
  pricemodel_company: _basePathCommon + "pricemodel/company",
  plan: _basePathCommon + "plan",
  plan_salesagent: _basePathCommon + "plan/salesagent",

  // 請求金額一覧取得		/salesagent/{salesagent_id}/billing/list
  detail_billing_salesagent:
    _basePathCommon + "salesagent/{salesagent_id}/billing/list",

  // ロゴ画像設定取得（[機能設定]→[アプリ設定] 画像設定） /logo/settings
  logo_setting: _basePathCommon + "logo/settings",

  // アラート設定取得 （[機能設定]→[アプリ設定] メール通知設定）
  alert_setting: _basePathCommon + "alert/settings",

  // アラート設定取得 （[機能設定]→[アプリ設定] リマインダー通知設定）
  reminder_setting: _basePathCommon + "mi1/reminder/settings",

  // アプリのラベル設定
  app_label_settings: _basePathCommon + "app_label/settings",

  // アプリのラベル設定
  analysis_app_settings: _basePathCommon + "analysis/app/settings",

  // ユーザーアカウント一括ロック
  list_lock: _basePathCommon + "account/lock/list",

  // ユーザーアカウントロック
  user_lock: _basePathCommon + "account/lock",

  // ユーザーアカウントロック解除
  user_unlock: _basePathCommon + "account/unlock",

  //  ユーザーアカウント一括ロック解除
  list_unlock: _basePathCommon + "account/unlock/list",

  //  ユーザーアカウント一括一時ロック解除
  list_unlock_temporary:
    _basePathCommon + "account/temporary_locked/unlock/list",

  // ユーザーアカウント一時ロック解除用のメール送付
  request_unlock: _basePathCommon + "account/temporary_locked/unlock/request",

  // 契約者のMIMOSYS設定取得（[機能設定]→[アプリ設定] アプリ設定） /company/{company_id}/mi1/settings
  app_setting: _basePathCommon + "company/{company_id}/mi1/settings",

  // MIMOSYS読み上げ文設定取得（[機能設定]→[アプリ設定] 読み上げ文設定） /phrase/settings
  phrase_setting: _basePathCommon + "phrase/settings",

  // company 心の活量値 平均
  // MIMOSYS 契約者毎の心の活量値の平均値一覧取得(/analysis/mi1/average/list)
  info_graph_company: _basePathCommon + "analysis/mi1/average/list",

  // 契約者毎のMi-1こころ指数の頻度分布一覧取得
  info_frequency_distribution:
    _basePathCommon + "analysis/mi1/result/frequency_distribution",

  // department
  graph_department:
    _basePathMimosys + "data/graph/company/{company_id}/department",

  // employee 元気圧と心の活量値
  // MIMOSYS 一般ユーザー詳細 - 元気圧と心の活量値：MIMOSYS解析結果一覧取得(/analysis/mi1/list)
  info_graph_employee: _basePathCommon + "analysis/mi1/list",

  // 契約者詳細 csv出力(全データ)
  csv_company: _basePathCommon + "utility/mi1/list/export",

  // 契約者詳細 csv出力(部署平均)
  csv_department: _basePathCommon + "utility/mi1/average/export",

  // 契約者詳細 csv出力(こころ指数頻度分布)
  csv_frequency: _basePathCommon + "utility/mi1/frequency/export",

  // 一般ユーザー詳細 csv出力
  csv_employee: _basePathCommon + "utility/mi1/export",

  // dysphagia
  // 嚥下解析結果一覧取得
  list_dysphagia: _basePathCommon + "analysis/dysphagia/list_all",

  // 嚥下解析結果一覧CSV出力
  csv_dysphagia: _basePathCommon + "analysis/dysphagia/export",

  // MSA MCI解析結果一覧取得
  list_msa_mci: _basePathCommon + "analysis/mci/list_all",

  // MSA MCI解析結果一覧取得(csv)
  csv_msa_mci: _basePathCommon + "utility/mci/list/export",

  user_cog: _basePathCommon + "analysis/cog/list",
  cog_list_all: _basePathCommon + "analysis/cog/list_all",
  user_np1: _basePathCommon + "analysis/np1/list",
  np1_list_all: _basePathCommon + "analysis/np1/list_all",

  // co1
  // Co-1解析結果一覧
  user_co1: _basePathCommon + "analysis/co1/list",

  // Co-1全ユーザー解析結果一覧取得
  co1_list_all: _basePathCommon + "analysis/co1/list_all",

  // Co-1解析結果の一覧取得(csv)
  user_co1_csv: _basePathCommon + "utility/co1/export",

  co1_list_csv: _basePathCommon + "utility/co1/list/export",

  // co2
  // Co-2解析結果一覧
  user_co2: _basePathCommon + "analysis/co2/list",

  // Co-2全ユーザー解析結果一覧取得
  co2_list_all: _basePathCommon + "analysis/co2/list_all",

  // Co-2解析結果の一覧取得(csv)
  user_co2_csv: _basePathCommon + "utility/co2/export",

  co2_list_csv: _basePathCommon + "utility/co2/list/export",

  // Mi2
  // Mi-2解析結果一覧取得
  user_mi2: _basePathCommon + "analysis/mi2/list",

  // Mi-2全ユーザー解析結果一覧取得
  user_mi2_all: _basePath + "analysis/mi2/list_all",

  // Mi-2解析結果の一覧取得(csv)
  user_mi2_csv: _basePathCommon + "utility/mi2/export",
  mi2_list_csv: _basePathCommon + "utility/mi2/list/export",

  // Br-1
  // Br-1解析結果一覧取得
  user_br1: _basePathCommon + "analysis/br1/list",
  // Br-1全ユーザー解析結果一覧取得
  br1_list_all: _basePathCommon + "analysis/br1/list_all",
  // Br-1解析結果の一覧取得(csv)
  user_br1_csv: _basePathCommon + "utility/br1/export",
  // Br-1契約者に所属する全一般ユーザーの解析結果一覧取得(csv)
  br1_list_csv: _basePathCommon + "utility/br1/list/export",

  // Sl-1
  // Sl-1解析結果一覧取得
  user_sl1: _basePathCommon + "analysis/sl1/list",
  // Sl-1全ユーザー解析結果一覧取得
  sl1_list_all: _basePathCommon + "analysis/sl1/list_all",
  // Sl-1解析結果の一覧取得(csv)
  user_sl1_csv: _basePathCommon + "utility/sl1/export",
  // Sl-1契約者に所属する全一般ユーザーの解析結果一覧取得(csv)
  sl1_list_csv: _basePathCommon + "utility/sl1/list/export",

  // メールアドレス変更
  change_email: _basePathCommon + "account/user_id",

  all_billing_list: _basePathCommon + "billing/list",

  // 一般ユーザー解析回数取得 /statistics/count
  statistics_count: _basePathCommon + "analysis/statistics/count",

  // ダッシュボード関連API(PST)
  user_trend_all: _basePathCommon + "utility/analytics/user_trend/all",
  sales_trend_per_salesagent:
    _basePathCommon + "utility/analytics/sales_trend_per_salesagent",
  alerts_trend_all: _basePathCommon + "utility/analytics/alerts_trend/all",
  analysis_trend_all: _basePathCommon + "utility/analytics/analysis_trend/all",
  employees_trend_all:
    _basePathCommon + "utility/analytics/employees_trend/all",
};

export default {
  methods: {
    getWebDKURL(param) {
      return _postUrls[param];
    },
  },
};

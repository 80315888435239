import ErrorCode from "@/mixins/errorCode.js";
import Api from "@/mixins/api.js";
import SaveAs from "file-saver";
import VueJwtDecode from "vue-jwt-decode";

export default {
  mixins: [ErrorCode],
  computed: {
    displayNumber: function () {
      return function (val) {
        if (val === null || val === "") {
          return "";
        } else if (isNaN(Number(val))) {
          return val;
        }

        return new Intl.NumberFormat("ja-JP").format(val);
      };
    },
    displayYen: function () {
      return function (val) {
        if (val === null || val === "") {
          return "";
        } else if (isNaN(Number(val))) {
          return val;
        }

        return new Intl.NumberFormat("ja-JP", {
          style: "currency",
          currency: "JPY",
        }).format(val);
      };
    },
  },
  methods: {
    dispNotFound() {
      this.$router.replace({ name: "NotFound" });
    },

    isPst() {
      // PST権限
      if (
        this.$cookies.get("user") !== null &&
        this.$cookies.get("user").user_type === "pst"
      ) {
        return true;
      }
      return false;
    },

    isSalesagent() {
      // OEM先企業
      if (
        this.$cookies.get("user") !== null &&
        this.$cookies.get("user").user_type === "salesagent"
      ) {
        return true;
      }
      return false;
    },

    isCompany() {
      // 契約者
      if (
        this.$cookies.get("user") !== null &&
        this.$cookies.get("user").user_type === "company"
      ) {
        return true;
      }
      return false;
    },

    isEmployee() {
      // 一般ユーザー
      if (
        this.$cookies.get("user") !== null &&
        this.$cookies.get("user").user_type === "employee"
      ) {
        return true;
      }
      return false;
    },

    isPhysician() {
      // データ閲覧者
      if (
        this.$cookies.get("user") !== null &&
        this.$cookies.get("user").user_type === "physician"
      ) {
        return true;
      }
      return false;
    },
    hasEngineTypes(engineType) {
      if (this.isPst()) {
        return true;
      } else {
        const accessTokenDecode = VueJwtDecode.decode(
          this.$cookies.get("access_token"),
        );
        const engineTypes = Object.keys(accessTokenDecode.engineTypes);
        return engineTypes.includes("all") || engineTypes.includes(engineType);
      }
    },

    hasEngineOptions(engineType, options) {
      if (this.isPst()) {
        return true;
      } else {
        const accessTokenDecode = VueJwtDecode.decode(
          this.$cookies.get("access_token"),
        );
        const engineTypes = Object.keys(accessTokenDecode.engineTypes);
        if (engineTypes.includes(engineType)) {
          for (var option in options) {
            if (
              !(
                option in accessTokenDecode.engineTypes[engineType].options &&
                options[option] ==
                  accessTokenDecode.engineTypes[engineType].options[option]
              )
            ) {
              return false;
            }
          }
          return true;
        }

        return engineTypes.includes(engineType);
      }
    },

    hasEngineTypesMi1() {
      return this.hasEngineTypes("mi1");
    },
    hasEngineTypesMi2() {
      return this.hasEngineTypes("mi2");
    },
    hasEngineTypesCo1() {
      return this.hasEngineTypes("co1");
    },
    hasEngineTypesCo2() {
      return this.hasEngineTypes("co2");
    },
    hasEngineTypesBr1() {
      return this.hasEngineTypes("br1");
    },
    hasEngineTypesSl1() {
      return this.hasEngineTypes("sl1");
    },
    hasEngineTypesMci() {
      return this.hasEngineTypes("mci");
    },
    hasEngineTypesDysphagia() {
      return this.hasEngineTypes("dysphagia");
    },
    /*
     * 言語コード取得
     */
    getLangCode() {
      return this.$cookies.get("language") != null
        ? this.$cookies.get("language")
        : "ja";
    },

    /*
     * パスワードリセット
     */
    sendResetPassword(user_id, funcOK, funcNG) {
      const url = this.getWebDKURL("password_reset");
      const sendData = {
        user_id: user_id,
        language: this.getLangCode(),
      };
      Api.sendApi_1_1_Post(
        url,
        sendData,
        this.$cookies.get("access_token"),
        funcOK,
        funcNG,
      );
    },
    // Dialog
    initDialog(title, text, execOk, execCancel) {
      if (typeof val !== this.$root.$refs.commonDialog) {
        this.$root.$refs.commonDialog.initDialog(
          title,
          text,
          execOk,
          execCancel,
        );
      }
    },
    setDialogTitle(title) {
      if (typeof val !== this.$root.$refs.commonDialog) {
        this.$root.$refs.commonDialog.setDialogTitle(title);
      }
    },
    setDialogText(text) {
      if (typeof val !== this.$root.$refs.commonDialog) {
        this.$root.$refs.commonDialog.setDialogText(text);
      }
    },
    openDialog() {
      if (typeof val !== this.$root.$refs.commonDialog) {
        this.$root.$refs.commonDialog.open();
      }
    },

    // Snackbar
    initSnackbar() {
      if (typeof val !== this.$root.$refs.commonSnackbar) {
        this.$root.$refs.commonSnackbar.init();
      }
    },
    dispSuccess(msg) {
      if (typeof val !== this.$root.$refs.commonSnackbar) {
        this.$root.$refs.commonSnackbar.dispSuccess(msg);
      }
    },
    dispWarning(msg) {
      if (typeof val !== this.$root.$refs.commonSnackbar) {
        this.$root.$refs.commonSnackbar.dispWarning(msg);
      }
    },
    dispError(msg) {
      if (typeof val !== this.$root.$refs.commonSnackbar) {
        this.$root.$refs.commonSnackbar.dispError(msg);
      }
    },

    // Loading
    startLoading() {
      if (typeof val !== this.$root.$refs.commonLoading) {
        this.$root.$refs.commonLoading.start();
      }
    },
    stopLoading() {
      if (typeof val !== this.$root.$refs.commonLoading) {
        this.$root.$refs.commonLoading.stop();
      }
    },
    startLoading2() {
      if (typeof val !== this.$root.$refs.commonLoading2) {
        this.$root.$refs.commonLoading2.start();
      }
    },
    stopLoading2() {
      if (typeof val !== this.$root.$refs.commonLoading2) {
        this.$root.$refs.commonLoading2.stop();
      }
    },
    startLoadingLine() {
      if (typeof val !== this.$root.$refs.commonLoadingLine) {
        this.$root.$refs.commonLoadingLine.start();
      }
    },
    stopLoadingLine() {
      if (typeof val !== this.$root.$refs.commonLoadingLine) {
        this.$root.$refs.commonLoadingLine.stop();
      }
    },

    checkCommonError(e) {
      if (!e.response) {
        this.dispError(this.ERROR_CODES["E_COMM_999"]);
        return true;
      }
      if (e.response.status >= 500) {
        this.dispError(this.ERROR_CODES["E_COMM_999"]);
        return true;
      } else {
        if (e.response.status == 401) {
          // console.log("e.response.data:" + JSON.stringify(e.response.data,null,'\t'));
          // console.log("e.response.data.detail.type:" + JSON.stringify(e.response.data.detail.type,null,'\t'));
          switch (e.response.data.detail.type) {
            case "auth_error.invalid_input": // ユーザーIDまたはパスワード誤り
              this.dispError(this.ERROR_CODES["E_LGIN_003"]);
              break;
            case "auth_error.deleted": // ユーザーが削除されている
              this.dispError(this.ERROR_CODES["E_LGIN_005"]);
              break;
            case "auth_error.locked": // ユーザーがロックされている
              this.dispError(this.ERROR_CODES["E_LGIN_004"]);
              break;
            case "auth_error.no_contract": // OEM先企業の契約期限切れ
              this.dispError(this.ERROR_CODES["E_LGIN_006"]);
              break;
            case "auth_error.token_expired": // トークンの有効期限切れ
              this.dispError(this.ERROR_CODES["E_LGIN_007"]);
              break;
            case "auth_error.invalid_token": // トークン不正
              this.dispError(this.ERROR_CODES["E_LGIN_007"]);
              break;
            case "auth_error.locked_temporary": // ユーザーが一時ロックされている
              this.dispError(this.ERROR_CODES["E_LGIN_008"]);
              break;
            case "auth_error.invalid_google_credential": // SSOトークン不正
              this.dispError(this.ERROR_CODES["E_LGIN_009"]);
              break;
            default:
              this.dispError(this.ERROR_CODES["E_COMM_998"]);
              break;
          }
          this.$cookies.remove("user");
          // this.$router.push({name: 'Login'});
          return true;
        } else if (e.response.status == 403) {
          this.dispNotFound();
        } else if (e.response.status == 404) {
          this.dispNotFound();
        } else if (
          e.response.status == 422 &&
          e.response.config.method == "get"
        ) {
          // GETメソッドのときはNot Foundへ
          this.dispNotFound();
        }
      }
      return false;
    },
    /*
     * CSVファイル取得
     */
    getCsvFile(response, filenameDefault) {
      let filename = null;
      // responseのContent-Dispositionからファイル名取得
      if (typeof response.headers["content-disposition"] !== "undefined") {
        const contentDisposition = response.headers["content-disposition"];

        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; // 正規表現でfilenameを抜き出す
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = decodeURI(matches[1].replace(/['"]/g, ""));
        }
      }

      if (filename == null) {
        filename = filenameDefault;
      }

      const blob = new Blob([response.data], { type: response.data.type });
      SaveAs(blob, filename);
    },
    /*
     * 解析結果CSVファイル取得
     */
    getAnalysisResultCsvFile(response) {
      this.getCsvFile(response, "data.csv");
    },
    /*
     * パスワードCSVファイル取得
     */
    getPasswordCsvFile(response) {
      this.getCsvFile(response, "password.csv");
    },

    getTabHref(tab_name) {
      return `/#${this.$route.path}#${tab_name}`;
    },

    getUserIdParameter(user_type, user_id) {
      if (!user_id) {
        return;
      }
      return `${user_type}_id=${encodeURIComponent(user_id)}`;
    },
    mergeDeep(target, source) {
      for (const key in source) {
        if (source[key] instanceof Object) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    },
  },
};

<template>
  <div v-if="loading" class="fullview">
    <v-progress-circular
      :indeterminate="true"
      :size="70"
      :width="5"
      color="primary"
    />
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
  }),
  methods: {
    start() {
      this.loading = true;
    },
    stop() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fullview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(#000, 0.4);
}
</style>

<template>
  <v-layout>
    <v-dialog v-model="dialog" :max-width="maxWidth">
      <v-card>
        <v-card-title :aria-label="dialogTitle"></v-card-title>
        <v-card-text style="white-space: pre-wrap; word-wrap: break-word">
          {{ dialogText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="mx-5" @click="execute">
            {{ $t("E_DIAT_012") }}
          </v-btn>
          <v-btn @click="cancel">
            {{ $t("E_DIAT_013") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: Number,
      default: 500,
    },
    executeText: {
      type: String,
      default: "はい",
    },
    cancelText: {
      type: String,
      default: "いいえ",
    },
  },
  data: () => ({
    dialog: false,
    dialogTitle: "",
    dialogText: "",
    execOk: "",
    execCancel: "",
  }),
  methods: {
    execute() {
      this.close();
      this.execOk();
    },
    cancel() {
      this.close();
      this.execCancel();
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    initDialog(title, text, execOk, execCancel) {
      this.setDialogTitle(title);
      this.setDialogText(text);
      this.execOk = execOk;
      this.execCancel = execCancel;
    },
    setDialogTitle(val) {
      this.dialogTitle = val;
    },
    setDialogText(val) {
      this.dialogText = val;
    },
    setExecOk(func) {
      this.execOk = func;
    },
    setExecCancel(func) {
      this.execCancel = func;
    },
  },
};
</script>

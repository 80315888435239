<template>
  <div v-if="loading" class="fullview">
    <vue-loaders
      name="line-scale-pulse-out-rapid"
      color="#329691"
      scale="1.5"
    />
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
  }),
  methods: {
    start() {
      this.loading = true;
    },
    stop() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fullview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(#000, 0.2);
  padding-top: 100px;
  padding-left: 10px;
}
</style>

<template>
  <v-app class="custom_phase01">
    <v-main>
      <router-view />
      <common-dialog ref="commonDialog" />
      <commonSnackbar ref="commonSnackbar" />
      <commonLoading ref="commonLoading" :full-view="true" />
      <commonLoading2 ref="commonLoading2" :full-view="true" />
      <commonLoadingLine ref="commonLoadingLine" :full-view="true" />
    </v-main>
  </v-app>
</template>

<script>
import "./assets/css/styles.css";
import SnackBar from "./components/Snackbar.vue";
import Loading from "./components/Loading.vue";
import Loading2 from "./components/Loading_circular01.vue";
import LoadingLine from "./components/Loading_line01.vue";
import Dialog from "./components/Dialog.vue";
import { useAppLabelsStore } from "@/stores/appLabels.js";
import { i18n } from "@/main.js";
import { computed } from "vue";

export default {
  name: "App",
  components: {
    CommonSnackbar: SnackBar,
    CommonLoading: Loading,
    CommonLoading2: Loading2,
    CommonLoadingLine: LoadingLine,
    CommonDialog: Dialog,
  },
  data: () => ({}),
  setup() {
    const appLabelsStore = useAppLabelsStore();
    const label_vmanagement_app = computed(() =>
      appLabelsStore.appLabel("label_vmanagement_app"),
    );

    return {
      label_vmanagement_app,
      appLabels: appLabelsStore,
      i18n: i18n,
    };
  },
  watch: {
    label_vmanagement_app: {
      handler: function () {
        document.title = this.label_vmanagement_app;
      },
      // 初回表示時、F5画面更新時に反映させる為即時実行
      immediate: true,
    },
  },
  methods: {
    emitMyEvent() {
      this.emitter.emit("UPDATE_USERNAME", { eventContent: "String changed" });
    },
  },
};
</script>

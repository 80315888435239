import ja_json from './ja.json'
import en_json from './en.json'

export default{
    data: () => ({
        language: 
            {
                // 翻訳用のjson
                ja : ja_json,
                en : en_json,
            },
        label:[
            { label: "日本語", lang_code: "ja"},
            { label: "English", lang_code: "en"},
          ]
    })
}
import { defineStore } from "pinia";

export const useDrawerIndexStore = defineStore({
  id: "drawerIndex",
  state: () => {
    return {
      selected: "Top",
    };
  },
  actions: {
    setIndex(index) {
      this.selected = index;
    },
  },
});

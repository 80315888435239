import { defineStore } from "pinia";
import { i18n } from "@/main.js";
import Api from "@/mixins/api.js";
import Post from "@/mixins/post.js";
import VueCookies from "vue-cookies";
import LangSetting from "../../language/setting.js";
import CommonControl from "@/mixins/commonControl.js";

function getAppLabelsDefault() {
  let app_labels = {};
  Object.keys(LangSetting.data().language).forEach((language) => {
    let locale_message = i18n.global.getLocaleMessage(language);
    app_labels[language] = {
      label_vmanagement_app:
        locale_message["label_app_label_settings_vmanagement_app"],
      label_vitality: locale_message["label_app_label_settings_vitality"],
      label_mental_activity:
        locale_message["label_app_label_settings_mental_activity"],
      label_mind_index: locale_message["label_app_label_settings_mind_index"],
      label_mi1: locale_message["label_app_label_settings_mi1"],
      label_mi2: locale_message["label_app_label_settings_mi2"],
      label_co1: locale_message["label_app_label_settings_co1"],
      label_co2: locale_message["label_app_label_settings_co2"],
      label_br1: locale_message["label_app_label_settings_br1"],
      label_sl1: locale_message["label_app_label_settings_sl1"],
    };
  });
  return app_labels;
}

export const useAppLabelsStore = defineStore({
  id: "appLabels",
  state: () => {
    return {
      app_labels: getAppLabelsDefault(),
    };
  },
  getters: {
    appLabels: (state) => state.app_labels[i18n.global.locale.value],
    appLabel: (state) => {
      return (key) => state.app_labels[i18n.global.locale.value][key];
    },
  },
  actions: {
    setAppLabels(app_labels) {
      this.app_labels = app_labels;
    },
    setAppLabelsDefault() {
      this.setAppLabels(getAppLabelsDefault());
    },
    mergeAppLabelSettings(app_label_settings) {
      let app_labels = { ...getAppLabelsDefault() };
      CommonControl.methods.mergeDeep(app_labels, app_label_settings);
      return app_labels;
    },
    setAppLabelsFromAppLabelSettings(app_label_settings) {
      this.setAppLabels(this.mergeAppLabelSettings(app_label_settings));
    },
    getAppLabelSettings(salesagent_id) {
      let url = Post.methods.getWebDKURL("app_label_settings");
      if (salesagent_id) {
        url += `?${CommonControl.methods.getUserIdParameter(
          "salesagent",
          salesagent_id,
        )}`;
      }
      Api.sendApi_1_1_Bearer_Get(
        url,
        VueCookies.get("access_token"),
        this.getAppLabelSettingsOK,
        this.getAppLabelSettingsNG,
      );
    },
    getAppSettings(employee_id) {
      let url = Post.methods.getWebDKURL("analysis_app_settings");
      if (employee_id) {
        url += `?${CommonControl.methods.getUserIdParameter(
          "employee",
          employee_id,
        )}`;
      }
      Api.sendApi_1_1_Bearer_Get(
        url,
        VueCookies.get("access_token"),
        this.getAppSettingsOK,
        this.getAppLabelSettingsNG,
      );
    },
    getAppLabelSettingsOK(response) {
      this.setAppLabelsFromAppLabelSettings(response.data.app_label_settings);
    },
    getAppSettingsOK(response) {
      this.setAppLabels(response.data.app_labels);
    },
    getAppLabelSettingsNG() {},
  },
  persist: true,
});

export default {
  // エラーコードを使用する画面の読み込み時に呼ばれる
  data() {
    let _errorCodes = {
      I_COMM_001: this.$t("I_COMM_001"),
      I_COMM_002: this.$t("I_COMM_002"),
      I_COMM_003: this.$t("I_COMM_003"),
      I_COMM_004: this.$t("I_COMM_004"),
      I_COMM_005: this.$t("I_COMM_005"),
      E_COMM_001: this.$t("E_COMM_001"),
      E_COMM_002: this.$t("E_COMM_002"),
      E_COMM_003: this.$t("E_COMM_003"),
      E_COMM_004: this.$t("E_COMM_004"),
      E_COMM_005: this.$t("E_COMM_005"),
      E_COMM_006: this.$t("E_COMM_006"),
      E_COMM_007: this.$t("E_COMM_007"),
      E_COMM_998: this.$t("E_COMM_998"),
      E_COMM_999: this.$t("E_COMM_999"),
      E_LGIN_001: this.$t("E_LGIN_001"),
      E_LGIN_002: this.$t("E_LGIN_002"),
      E_LGIN_003: this.$t("E_LGIN_003"),
      E_LGIN_004: this.$t("E_LGIN_004"),
      E_LGIN_005: this.$t("E_LGIN_005"),
      E_LGIN_006: this.$t("E_LGIN_006"),
      E_LGIN_007: this.$t("E_LGIN_007"),
      E_LGIN_008: this.$t("E_LGIN_008"),
      E_LGIN_009: this.$t("E_LGIN_009"),
      W_LGIN_001: this.$t("W_LGIN_001"),
      E_RSET_001: this.$t("E_RSET_001"),
      E_SARG_001: this.$t("E_SARG_001"),
      E_SARG_002: this.$t("E_SARG_002"),
      E_SARG_003: this.$t("E_SARG_003"),
      E_SARG_004: this.$t("E_SARG_004"),
      E_SARG_005: this.$t("E_SARG_005"),
      E_SARG_006: this.$t("E_SARG_006"),
      E_SARG_007: this.$t("E_SARG_007"),
      E_SARG_008: this.$t("E_SARG_008"),
      E_SARG_009: this.$t("E_SARG_009"),
      E_SARG_010: this.$t("E_SARG_010"),
      E_SARG_011: this.$t("E_SARG_011"),
      E_SARG_012: this.$t("E_SARG_012"),
      E_SARG_013: this.$t("E_SARG_013"),
      E_SARG_014: this.$t("E_SARG_014"),
      E_PHRG_001: this.$t("E_PHRG_001"),
      E_USRG_001: this.$t("E_USRG_001"),
      E_PRMD_001: this.$t("E_PRMD_001"),
      E_PRMD_002: this.$t("E_PRMD_002"),
      E_PRMD_003: this.$t("E_PRMD_003"),
      E_PRMD_004: this.$t("E_PRMD_004"),
      E_PRMD_005: this.$t("E_PRMD_005"),
      E_PRMD_006: this.$t("E_PRMD_006"),
      E_PRMD_007: this.$t("E_PRMD_007"),
      E_PRMD_008: this.$t("E_PRMD_008"),
      E_PRMD_009: this.$t("E_PRMD_009"),
      E_CSVI_001: this.$t("E_CSVI_001"),
      E_CSVI_002: this.$t("E_CSVI_002"),
      E_CSVI_003: this.$t("E_CSVI_003"),
      E_CSVI_004: this.$t("E_CSVI_004"),
      E_CSVI_005: this.$t("E_CSVI_005"),
      E_CSVI_006: this.$t("E_CSVI_006"),
      E_CSVI_007: this.$t("E_CSVI_007"),
      E_LOGO_001: this.$t("E_LOGO_001"),
      E_LOGO_002: this.$t("E_LOGO_002"),
      E_LOGO_003: this.$t("E_LOGO_003"),
      E_PASS_001: this.$t("E_PASS_001"),
      E_PLAN_001: this.$t("E_PLAN_001"),
      E_PLAN_002: this.$t("E_PLAN_002"),
      E_MCIL_001: this.$t("E_MCIL_001"),
    };
    return {
      ERROR_CODES: _errorCodes,
    };
  },
  methods: {
    // ログイン画面で言語設定変更時のみ呼ばれる
    reloadErrorCode: function () {
      let _errorCodes = {
        I_COMM_001: this.$t("I_COMM_001"),
        I_COMM_002: this.$t("I_COMM_002"),
        I_COMM_003: this.$t("I_COMM_003"),
        I_COMM_004: this.$t("I_COMM_004"),
        I_COMM_005: this.$t("I_COMM_005"),
        E_COMM_001: this.$t("E_COMM_001"),
        E_COMM_002: this.$t("E_COMM_002"),
        E_COMM_003: this.$t("E_COMM_003"),
        E_COMM_004: this.$t("E_COMM_004"),
        E_COMM_005: this.$t("E_COMM_005"),
        E_COMM_006: this.$t("E_COMM_006"),
        E_COMM_007: this.$t("E_COMM_007"),
        E_LGIN_008: this.$t("E_LGIN_008"),
        E_COMM_998: this.$t("E_COMM_998"),
        E_COMM_999: this.$t("E_COMM_999"),
        E_LGIN_001: this.$t("E_LGIN_001"),
        E_LGIN_002: this.$t("E_LGIN_002"),
        E_LGIN_003: this.$t("E_LGIN_003"),
        E_LGIN_004: this.$t("E_LGIN_004"),
        E_LGIN_005: this.$t("E_LGIN_005"),
        E_LGIN_006: this.$t("E_LGIN_006"),
        E_LGIN_007: this.$t("E_LGIN_007"),
        E_LGIN_009: this.$t("E_LGIN_009"),
        W_LGIN_001: this.$t("W_LGIN_001"),
        E_SARG_001: this.$t("E_SARG_001"),
        E_SARG_002: this.$t("E_SARG_002"),
        E_SARG_003: this.$t("E_SARG_003"),
        E_SARG_004: this.$t("E_SARG_004"),
        E_SARG_005: this.$t("E_SARG_005"),
        E_SARG_006: this.$t("E_SARG_006"),
        E_SARG_007: this.$t("E_SARG_007"),
        E_SARG_008: this.$t("E_SARG_008"),
        E_SARG_009: this.$t("E_SARG_009"),
        E_SARG_010: this.$t("E_SARG_010"),
        E_SARG_011: this.$t("E_SARG_011"),
        E_SARG_012: this.$t("E_SARG_012"),
        E_SARG_013: this.$t("E_SARG_013"),
        E_SARG_014: this.$t("E_SARG_014"),
        E_PHRG_001: this.$t("E_PHRG_001"),
        E_USRG_001: this.$t("E_USRG_001"),
        E_PRMD_001: this.$t("E_PRMD_001"),
        E_PRMD_002: this.$t("E_PRMD_002"),
        E_PRMD_003: this.$t("E_PRMD_003"),
        E_PRMD_004: this.$t("E_PRMD_004"),
        E_PRMD_005: this.$t("E_PRMD_005"),
        E_PRMD_006: this.$t("E_PRMD_006"),
        E_PRMD_007: this.$t("E_PRMD_007"),
        E_PRMD_008: this.$t("E_PRMD_008"),
        E_PRMD_009: this.$t("E_PRMD_009"),
        E_CSVI_001: this.$t("E_CSVI_001"),
        E_CSVI_002: this.$t("E_CSVI_002"),
        E_CSVI_003: this.$t("E_CSVI_003"),
        E_CSVI_004: this.$t("E_CSVI_004"),
        E_CSVI_005: this.$t("E_CSVI_005"),
        E_CSVI_006: this.$t("E_CSVI_006"),
        E_CSVI_007: this.$t("E_CSVI_007"),
        E_LOGO_001: this.$t("E_LOGO_001"),
        E_LOGO_002: this.$t("E_LOGO_002"),
        E_LOGO_003: this.$t("E_LOGO_003"),
        E_PASS_001: this.$t("E_PASS_001"),
        E_PLAN_001: this.$t("E_PLAN_001"),
        E_PLAN_002: this.$t("E_PLAN_002"),
        E_MCIL_001: this.$t("E_MCIL_001"),
      };
      return {
        ERROR_CODES: _errorCodes,
      };
    },
  },
};
